import React, { useState, useEffect, useRef } from 'react'
import {Form, Button, DropdownButton, Dropdown, ProgressBar, Spinner} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import { Container } from 'reactstrap';
import './Drones.css';
import ModemCard from './ModemCard';
import Modem from './Modem';
import QrCodeScannerModal from "../modal/qrCodeScannerModal/qrCodeScannerModal";
import {useTranslation} from "react-i18next";

function Bind(){
    const { t } = useTranslation(['modem']);
    const protocol = new signalR.JsonHubProtocol();

    const [hubBind, setHubBind] = useState(hubConnect);

    const [pressedBindInfo, setPressedBindInfo] = useState(false);
    const [modemNotOnlineInfo, setModemNotOnlineInfo] = useState(<></>);
    const [bindTime, setBindTime] = useState(40);
    const [codeValue, setCodeValue] = useState('');
    const [isQrCodeScannerModalOpen, setQrCodeScannerModalOpen] = useState(false);

    const onChangeCodeInput = (event) => {
        event.preventDefault();
        setCodeValue(event.target.value);
    };

    const onScanQrCodeClick = () => {
        setQrCodeScannerModalOpen(true);
    }

    const onDecode = (decodedText) => {
        setCodeValue(decodedText.match("IMEI:([0-9]*)")[1]);
        setQrCodeScannerModalOpen(false);
    }

    const timerRef = useRef(null);


    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();
        connection.on('BindIsOk', (message) => {console.log("bind is ok. imei:" + message.imei); BindIsOk(message)});
        connection.start();
        return connection;
    }

    function BindIsOk(){
        window.location.reload();
    }

    useEffect(() => {
        console.log("bind mount");
        startTimer();
      }, []);

    // таймер срабатывает по клику
    const startTimer = () => {
      timerRef.current = setTimeout(() => {
        setPressedBindInfo(false);
      }, bindTime * 1000);
    }

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current); // очистка таймера
            console.log("bind.js unmount");
            hubBind.stop();
        };
    }, []);

    function submitFormBindModem(event){
        event.preventDefault()
        console.log(event.target[0].value)
    
        axios.post('api/drone/bindModem', {imei: event.target[0].value})
            .then(response => {
                console.log(response); 
                

                if(response.data.state == "NotPressedBind"){
                    setPressedBindInfo(true);
                    console.log("bind time: " + response.data.bindTime);
                    setBindTime(response.data.bindTime);
                    clearTimeout(timerRef.current);
                    startTimer();
                }

                if(response.data.state == "ModemNotOnline"){
                    setModemNotOnlineInfo(<>
                        <p>{t('error')}</p>
                    </>);
                }
            });
    }

    return(
        <>
            <h3>{t('adding_device')}</h3>

            <Form onSubmit={submitFormBindModem}>
                <Form.Control
                    type="text"
                    placeholder="IMEI"
                    value={codeValue}
                    onChange={onChangeCodeInput}
                />
                <br />

                {(pressedBindInfo == false) ?
                    <div className='bind-buttons'>
                      <Button variant="primary" type="submit">
                          Bind
                      </Button>
                      <Button variant="secondary" onClick={onScanQrCodeClick}>
                          Scan QR-Code
                      </Button>
                    </div> :
                    <div className='bind-spinner-info'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <div>{t('press_bind_button_1')}{bindTime}{t('press_bind_button_2')}</div>
                    </div>
                }
                {modemNotOnlineInfo}
            </Form>
            <QrCodeScannerModal
                show={isQrCodeScannerModalOpen}
                onDecode={onDecode}
                onClose={() => setQrCodeScannerModalOpen(false)}
            />
        </>
    )
}

export default Bind;