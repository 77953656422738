import React, { useState, useEffect } from 'react'

import {Button} from "react-bootstrap";
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import './Statistics.css';
import { Link } from 'react-router-dom'

function Statistic(){
  const data1 = [6, 0, 0, 0, 0, 0, 0];
  const [uniqueVisitsByDay, setUniqueVisitsByDay] = useState([0, 0, 0, 0, 0, 0, 0])
  const [lifetime, setLifetime] = useState(null)
  const [logs, setLogs] = useState(null)
  const [logPage, setLogPage] = useState(0)
  const [logsSize, setLogsSize] = useState(0);
    
    useEffect(() => {
      console.log("statistic mount");
      getFullStatistic();
      getLogsSize();
    }, []);

    function getFullStatistic(){
      axios.get('api/statistic/getFullStatistic')
        .then(response => {
            setUniqueVisitsByDay(response.data.uniqueVisitsByDay.map((visit) => { return { count: visit.count, name: visit.date.split('T')[0] }}));
            setLifetime(response.data.lifetimeStatistic);
        }
      );

      updateLog(logPage);
    }

    function getLogsSize(){
      axios.get('api/statistic/getLogsSize')
        .then(response => {
            setLogsSize(response.data);
        }
      );
    }

    function updateLog(page){
      axios.get('api/statistic/getLogs?count=100&page=' + page)
        .then(response => {
          setLogs(response.data)
        }
      );
    }

    function clearLogs(){
      axios.post('api/statistic/clearLogs')
        .then(response => {console.log("logs clear")})
    }

    function logPageUp(){
      var lp = logPage + 1
      setLogPage(lp)
      updateLog(lp)
    }

    function logPageDown(){
      var lp = logPage - 1
      setLogPage(lp)
      updateLog(lp)
    }

    return(
      <div className='blocks'>
        <div className='body-box'>
          <p>Уникальные заходы</p>
          <div>
              {/*<Line options={options} data={datas} />*/}
              <BarChart
                  width={900}
                  height={300}
                  data={uniqueVisitsByDay}
                  margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                  }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="count" fill="#FF1493" />
              </BarChart>
          </div>
          <div>
            <p>Время работы на этой неделе:</p>
            {lifetime != null ? 
              <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                  <tr>
                    <th>Imei</th>
                    <th>Lifetime min</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {lifetime.map(lifetime =>
                    <tr key={lifetime.imei}>
                      <td><Link to={`/statistics/modem?imei=${lifetime.imei}`}>{lifetime.imei}</Link></td>
                      <td>{lifetime.lifetime.toFixed(2)}</td>
                      <td>{lifetime.email}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              : <></>}
          </div>
          <div>
            <p>Информация по модемам:</p>
            <Button href='/statistics/modems'>Перейти</Button>
          </div>
          <div  style={{marginTop: "50px"}}>
            <p>Logs:</p>
            <div className='logs-info'>
              <p>Logs size: {(logsSize / 1024 / 1024).toFixed(2)} MB</p>
              <Button style={{backgroundColor: "#c21b1b"}} onClick={clearLogs}>Очистить</Button>
            </div>
            
            <div className='nav-buttons'>
              <Button disabled={logPage <= 0} onClick={logPageDown}>{logPage - 1}</Button>
              <div style={{alignItems: "center", justifyItems: "center", display:"flex", width:"100%", marginLeft:"40px"}}>{logPage}</div>
              <Button onClick={logPageUp}>{logPage + 1}</Button>
            </div>
            {logs != null ?
              <table className='table table-striped' aria-labelledby="tabelLabel" style={{tableLayout: "fixed"}}>
                <thead>
                  <tr>
                    <th style={{width: "220px"}}>Time</th>
                    <th style={{width: "40px"}}>Lvl</th>
                    <th style={{width: "150px"}}>Machine</th>
                    <th>Message</th>
                    <th>Trace</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map(l =>
                    <tr key={l.time}>
                      <td>{l.time}</td>
                      <td>{l.level}</td>
                      <td>{l.machineName}</td>
                      <td style={{fontSize: "15px"}}>{l.message}</td>
                      <td style={{fontSize: "15px"}}>{l.trace}</td>
                    </tr>
                  )}
                </tbody>
              </table> 
              : <></>
            }
            <div className='nav-buttons'>
              <Button disabled={logPage <= 0} onClick={logPageDown}>{logPage}</Button>
              <div style={{alignItems: "center", justifyItems: "center", display:"flex", width:"100%", marginLeft:"40px"}}>{logPage + 1}</div>
              <Button onClick={logPageUp}>{logPage + 2}</Button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Statistic;