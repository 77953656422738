import React, { useState } from 'react'
import { Link, Outlet } from "react-router-dom";
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import './Wiki.css';
import {useTranslation} from "react-i18next";

function Wiki(){
    const { t } = useTranslation('base');

    return(
        <div className='blocks'>
            <div className='body-box'>
                <div className='wiki'>
                    <div className='wiki-grid' style={{  }}>
                        <nav
                            className='wiki-nav'
                            style={{
                            }}
                        >
                            <Link
                                style={{ display: "block" }}
                                to={`/wiki/${"QuickStart"}`}
                                key={"invoicenumber"}
                            >
                                {t('fast_start')}
                            </Link>
                            <Link
                                style={{ display: "block"}}
                                to={`/wiki/${"ogn"}`}
                                key={"invoicenumber"}
                            >
                                {t('ogn')}
                            </Link>
                        </nav>
                        <div className='wiki-content'>
                            <Outlet />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wiki;
