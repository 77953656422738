import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';


function SortCard({onlineTopClick}){
    useEffect(() => {
        //console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            //console.log("airmap unmount");
            //hubDrone.stop();
        };
    }, []);

    return(
        <div className={'sort-card body-box'} onClick={onlineTopClick}>
            <div className='sort-card-online'>
                <p>Online to top</p>
            </div>
        </div>
    )
}

export default SortCard;
