import React, {Component, Suspense} from 'react';
import './Home.css'
import '../blocks/Block.css'
import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";


export default function Home () {
    const { t } = useTranslation(['home', 'base']);

    return (
        <div className='body-box-home'>
          <div className='desktop'>
            <img className='label-img' style={{width: "100%"}} src="/image/Back.jpg"></img>
            <div class="label-text">
              <div class="label-text-big">AIR-LINK</div>
              <div class="label-text-mid">{t('header_1_description_1')}</div>
              <div class="label-text-small">{t('header_1_description_2')}</div>
                {i18n.language === "ru" ?
                  <Button className="buy-button" href='https://rushop.air-link.space' variant="success" ><div>{t('Buy', {ns: 'base'})}</div></Button> :
                  <Button className="buy-button" href='https://cli.co/lyfnzYl' variant="success" ><div>{t('Buy', {ns: 'base'})}</div></Button>
                }
                
            </div>
          </div>
          <div className='mobile'>
            <img className='label-img' style={{width: "100%"}} src="/image/BackMobile.jpg"></img>
            <div class="label-text">
              <div class="label-text-big">Air-Link</div>
              <div class="label-text-mid">{t('header_1_description_1')}</div>
              <div class="label-text-small">{t('header_1_description_2')}</div>
              {i18n.language.includes("ru") ?
                <Button className="buy-button" href='https://rushop.air-link.space' variant="success" ><div>{t('Buy', {ns: 'base'})}</div></Button> :
                <Button className="buy-button" href='https://cli.co/lyfnzYl' variant="success" ><div>{t('Buy', {ns: 'base'})}</div></Button>
              }
            </div>
          </div>
          <div className='modem-info'>
            {/*<div className='introduction'>
              <div className='heading'>Air-Link</div>
              <div className='heading-small'>Модем телеметрии для автопилота</div>
              <div></div>
              <img src="/image/modem1.png"></img>
            </div>*/}
            <div className='client-support modem-info-block'>
              <div className='heading'>{t('support_header')}</div>
              <div>{t('support_description')}</div>
              <div className='gs-button-info'>
                <div>
                  <a href='/mp'>
                    <img src="/image/MP.png" />
                    <div>Mission Planner</div>
                  </a>

                </div>
                <div>
                  <a href='/qgc'>
                    <img src="/image/QGC.png" />
                    <div>QGroundControl</div>
                  </a>
                </div>
              </div>
            </div>

            <div className='ogn-rtk modem-info-block'>
              <div className='ogn-rtk-container'>
                <div>
                  <div className='heading-small'>ADSB/OGN/FLARM</div>
                  <div>
                      {t('paragraph_1_string_1')}
                    <a href='https://www.flightradar24.com/'> FlightRadar24 </a>
                      {t('and', {ns: 'base'})}
                    <a href='https://live.glidernet.org/'> OGN </a>
                  </div>
                </div>
                <div>
                  <img src='/image/fl.jpg'/>
                </div>
              </div>
              <div className='desktop '>
                <div className='ogn-rtk-container'>
                  <div>
                    <img src='/image/satellite.jpg'/>
                  </div>
                  <div>
                    <div className='heading-small'>{t('paragraph_2_header')}</div>
                    <div>
                        {t('paragraph_2_string_1')}
                      <a href={t('paragraph_2_link_1')}>{t('paragraph_2_string_2')}</a>
                        {t('paragraph_2_string_3')}
                    </div>
                  </div>
                </div>
              </div>

              <div className='ogn-rtk-container mobile'>

                <div>
                  <div className='heading-small'>{t('paragraph_2_header')}</div>
                  <div>
                      {t('paragraph_2_string_1')}
                      <a href={t('paragraph_2_link_1')}>{t('paragraph_2_string_2')}</a>
                      {t('paragraph_2_string_3')}
                  </div>
                </div>
                <div>
                  <img src='/image/satellite.jpg'/>
                </div>
              </div>
            </div>
            <div className='specifications modem-info-block'>
              <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                  <tr>
                    <th>{t('table.header')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                    <tr key='{forecast.version}'>
                      <td>{t('table.frequency.title')}</td>
                      <td><p>{t('table.frequency.string_1')}</p><p>{t('table.frequency.string_2')}</p><p>{t('table.frequency.string_3')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.speed.title')}</td>
                      <td><p>{t('table.speed.string_1')}</p><p>{t('table.speed.string_2')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.firmware.title')}</td>
                      <td><p>{t('table.firmware.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.sim.title')}</td>
                      <td><p>{t('table.sim.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.interface.title')}</td>
                      <td><p>{t('table.interface.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.protocol_support.title')}</td>
                      <td><p>{t('table.protocol_support.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.amendment_formats.title')}</td>
                      <td><p>{t('table.amendment_formats.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.voltage.title')}</td>
                      <td><p>{t('table.voltage.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.power.title')}</td>
                      <td><p>{t('table.power.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.dimensions.title')}</td>
                      <td><p>{t('table.dimensions.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.weight.title')}</td>
                      <td><p>{t('table.weight.string_1')}</p></td>
                    </tr>
                    <tr key='{forecast.version}'>
                      <td>{t('table.antenna_connector.title')}</td>
                      <td><p>{t('table.antenna_connector.string_1')}</p></td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div className='contacts modem-info-block'>
              <div className='heading'>{t('contacts.header')}</div>
              <div>{t('contacts.phone')}</div>
              <div>{t('contacts.email')}</div>
              <div><a href='https://t.me/ClearSkySupport'>Telegram</a></div>
              <div><a href='/yellcs'></a></div>
            </div>
          </div>
      </div>
    );
}
