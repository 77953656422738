import {Button, Modal} from "react-bootstrap";
import QrScanner from "../../abstract/qrScanner";

const QrCodeScannerModal = (props) => {
    return (
        <Modal show={props.show}>
            <Modal.Header>
                <Modal.Title style={{ color: 'black' }}>Отсканировать QR-код</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <QrScanner onDecode={props.onDecode} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={props.onClose}>Отмена</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QrCodeScannerModal;
