import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';


function ModemCard({imei, isOnline, onClick, isActive, modemName, version}){
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    useEffect(() => {
        //console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            //console.log("airmap unmount");
            //hubDrone.stop();
        };
    }, []);

    function modemVersionName(version) {
        if(version < 2000) {
            return "Telem" 
        }
        if(version < 3000) {
            return "EYE"
        }
    }

    return(
        <div className={'modem-card body-box' + (isActive ? " active-modem-card" : "") } onClick={onClick}>
            <div className='modem-version-name'>
                <p>{modemName}</p>
                <p className='modem-verion-text'>{modemVersionName(version)}</p>
            </div>
            
            <p>IMEI: {imei}</p>
            {isOnline ? <p className='is_online'>Online</p> : <p className='is_offline'>Offline</p>}
                
            
        </div>
    )
}

export default ModemCard;
