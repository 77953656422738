import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';
import {ReactComponent as BindLogo} from './BindDroneCard.svg';
import './Drones.css';


function BindCard({onClick}){

    useEffect(() => {
        //console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            //console.log("airmap unmount");
            //hubDrone.stop();
        };
    }, []);

    return(
        <div className='body-box bind-card' onClick={onClick}>
            <p>+</p>
        </div>
    )
}

export default BindCard;
