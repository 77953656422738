import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import {Button, Form} from "react-bootstrap";
import axios from 'axios';
import './Statistics.css';

function ModemsStatistic(){
  //const { imei } = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    const [info, setInfo] = useState(null)
    const [searchImei, setSearchImei] = useState("")
    const [searchEmail, setSearchEmail] = useState("")

    
    useEffect(() => {
      console.log("statistic modems mount");
      getModemsStatistic()
    }, []);

    function getModemsStatistic(){
        axios.post('api/statistic/getModemsStatistic', {imei: searchImei, email: searchEmail})
          .then(response => {
              console.log(response.data);
              setInfo(response.data);
          }
        );
    }

    return(
      <div className='blocks'>
        <div className='body-box'>
            {info != null ?
              <table className='table table-striped' aria-labelledby="tabelLabel" style={{tableLayout: "fixed"}}>
                <thead>
                  <tr>
                    <th style={{width: "15%"}}>IMEI</th>
                    <th style={{width: "17%"}}>User</th>
                    <th style={{width: "30%"}}>Comment</th>
                    <th style={{width: "15%"}}>Lifetime min</th>
                    <th style={{width: "8%"}}>ID</th>
                    <th style={{width: "10%"}}>Version</th>
                    <th style={{width: "8%"}}></th>
                  </tr>
                </thead>
                <tbody>
                    <tr key="key">
                      <td><Form.Control type="text" defaultValue={searchImei} onChange={(event) => {setSearchImei(event.target.value)}} /></td>
                      <td><Form.Control type="text" defaultValue={searchEmail} onChange={(event) => {setSearchEmail(event.target.value)}} /></td>
                      <td style={{fontSize: "15px"}}></td>
                      <td></td>
                      <td></td>
                      <td><button style={{width: "100%"}} className="btn btn-danger" onClick={() => {getModemsStatistic();}}>Find</button></td>
                    </tr>
                </tbody>
                <tbody>
                {info.map(i =>
                    <tr key={i.imei}>
                      <td><Link to={`/statistics/modem?imei=${i.imei}`}>{i.imei}</Link></td>
                      <td>{i.userName}</td>
                      <td style={{fontSize: "15px"}}>{i.comment}</td>
                      <td>{i.lifetime}</td>
                      <td>{i.name}</td>
                      <td>{i.version}</td>
                    </tr>
                )}
                </tbody>
              </table> 
              : <></>
            }
        </div>
      </div>
    )
}

export default ModemsStatistic;