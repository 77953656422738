import React, { useState } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import "./downloads.css"
import {useTranslation} from "react-i18next";

function Alp(){
    const { t } = useTranslation(['alp', 'base']);

    return(
        <div className='blocks'>
            <div className='body-box'>
                <div className='mission-download'>
                    <div className='mp-logo'>
                        <img src="/image/ALP.png" />
                        
                    </div>
                    <div>
                        <div className='heading'>{t('header')}</div>
                        <div className='text'>
                            <p>{t('string_1')}</p>
                        </div>
                    </div>
                    <div className='download-buttons'>
                        <p>{t('Download', { ns: 'base'})}:</p>
                        <Button variant='success' href='https://disk.yandex.ru/d/lsSntR_f5Adh2g' target='_blank'>Windows</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Alp;