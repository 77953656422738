import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import {Button, Form} from "react-bootstrap";
import axios from 'axios';
import './Statistics.css';

function ModemStatistic(){
  //const { imei } = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    const [info, setInfo] = useState(null)
    const [comment, setComment] = useState("")
    const [flightLogs, setFlightLogs] = useState(null)
    const [logPage, setLogPage] = useState(0)

    
    useEffect(() => {
      console.log("statistic mount");
      getModemStatistic()
      getFlightLogs(logPage)
    }, []);

    function getModemStatistic(){
        var imei = queryParams.get("imei")
        axios.get('api/statistic/getModemStatistic?imei='+imei)
          .then(response => {
              console.log(response.data);
              setInfo(response.data);
              setComment(response.data.comment);
          }
        );
    }

    function getFlightLogs(page){
      var imei = queryParams.get("imei")
      axios.post('api/flightLog/getFlightLogs', { imei: imei, page: page, count: 50 })
          .then(response => {
              console.log(response.data);
              setFlightLogs(response.data.flightLog);
          }
        );
    }

    function logPageUp(){
      var lp = logPage + 1
      setLogPage(lp)
      getFlightLogs(lp)
    }

    function logPageDown(){
      var lp = logPage - 1
      setLogPage(lp)
      getFlightLogs(lp)
    }

    function downloadLog(logId, fileName){
      var imei = queryParams.get("imei")
      axios({
        url: 'api/flightLog/download?logId=' + logId + "&imei=" + imei,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
    }

    function saveComment(){
        axios.post('api/statistic/setModemComment', {comment: comment, statId: info.statId})
            .then(response => {
                console.log(response);
        });
    }


    return(
      <div className='blocks'>
        <div className='body-box'>
            {info != null ?
              <table className='table table-striped' aria-labelledby="tabelLabel" style={{tableLayout: "fixed"}}>
                <thead>
                  <tr>
                    <th style={{width: "15%"}}>IMEI</th>
                    <th style={{width: "17%"}}>User</th>
                    <th style={{width: "30%"}}>Comment</th>
                    <th style={{width: "15%"}}>Lifetime min</th>
                    <th style={{width: "8%"}}>ID</th>
                    <th style={{width: "10%"}}>Version</th>
                    <th style={{width: "8%"}}></th>
                  </tr>
                </thead>
                <tbody>
                    <tr key={info.imei}>
                      <td>{info.imei}</td>
                      <td>{info.userName}</td>
                      <td style={{fontSize: "15px"}}><Form.Control type="text" defaultValue={comment} onChange={(event) => {setComment(event.target.value)}} /></td>
                      <td>{info.lifetime.toFixed(2)}</td>
                      <td>{info.name}</td>
                      <td>{info.version}</td>
                      <td><button style={{width: "100%"}} className="btn btn-danger" onClick={() => {saveComment();}}>Save</button></td>
                    </tr>
                </tbody>
              </table> 
              : <></>
            }
            <p>Logs:</p>
          {flightLogs != null ? 
          <table className='table table-striped' aria-labelledby="tabelLabel" style={{tableLayout: "fixed"}}>
            <thead>
              <tr>
                <th style={{width: "40%"}}>File name</th>
                <th style={{width: "17%"}}>Size</th>
                <th style={{width: "10%"}}></th>
              </tr>
            </thead>
            <tbody>
            {flightLogs.map(l =>
                    <tr key={l.fileName}>
                      <td>{l.fileName}</td>
                      <td>{l.fileSize == 0 ? "undefined" : (l.fileSize / 1024 / 1024).toFixed(4) + " MB"}</td>
                      <td><button style={{width: "100%"}} className="btn btn-danger" onClick={() => {downloadLog(l.logId, l.fileName);}}>Download</button></td>
                    </tr>
                  )}
            </tbody>
          </table>      
          : <></>
          }
          <div className='nav-buttons'>
            <Button disabled={logPage <= 0} onClick={logPageDown}>{logPage}</Button>
            <div style={{alignItems: "center", justifyItems: "center", display:"flex", width:"100%", marginLeft:"40px"}}>{logPage + 1}</div>
            <Button onClick={logPageUp}>{logPage + 2}</Button>
          </div>

        </div>
      </div>
    )
}

export default ModemStatistic;