export const FlashlightOn = (props) => {
    return (
        <svg
            width={props.width ?? 24}
            height={props.height ?? 24}
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d="M6 2h12v3H6zm0 5v1l2 3v11h8V11l2-3V7H6zm6 8.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                fill={props.color}
            />
        </svg>
    )
}