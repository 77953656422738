import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import 'leaflet/dist/leaflet.css';
import DroneIcon from "./drone.svg";
import L from "leaflet";
import {Point} from "leaflet/dist/leaflet-src.esm";
import RotatedMarker from './RotatedMarker';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Polygon,
    FeatureGroup,
    useMapEvents,
    useMap,
    useMapEvent, Rectangle, Polyline, Tooltip
  } from 'react-leaflet';

const position = [51.505, -0.09]

const GPSMapIcon = new L.Icon({
    iconUrl: DroneIcon,
    iconRetinaUrl: DroneIcon,
    iconAnchor: new L.Point(10, 30),
    popupAnchor: new L.Point(0, 0),
    //shadowUrl: HeliIcon,
    //shadowSize: new Point(20, 70),
    //shadowColor: "#000000",
    //shadowAnchor: new L.Point(10, 30),
    backgroundColor: "rgba(0,0,0,0)",
    //image: HelicopterImage,
    iconSize: new Point(22, 72),
  });

function Airmap(){
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    const [hubDrone, setHubDrone] = useState(hubConnect);
    const [dronePosition, setDronePosition] = useState(null);

    useEffect(() => {
        console.log("airmap mount");
      }, []);

    useEffect(() => {
        return () => {
            console.log("airmap unmount");
            hubDrone.stop();
        };
    }, []);

    function hubConnect(){
        let connection = new signalR.HubConnectionBuilder().withUrl("api/drone-hub").withHubProtocol(protocol).build();
        connection.on('MapPosition', (message) => {console.log(message); setDronePosition(message)});
        connection.start();
        return connection;
    }

    return(
        <>
        <div
            /*className="container-fluid d-flex flex-row"
            style={{
            width: "100vw",
            height: "100%",
            //minHeight: "100%",
            backgroundColor: "#000000",
            paddingLeft: 0,
            paddingRight: 0,
            }}*/
            //className="container-fluid d-flex flex-row"
            style={{
            width: "100%",
            height: "100%",
            //minHeight: "100%",
            position: "absolute",
            top: "55px",
            left: "0px",
            backgroundColor: "#000000",
            paddingLeft: 0,
            paddingRight: 0,
            zIndex: -1,
            }}
        >
        {/*<div className={'map_container'} style={{height: "100%", width: "100%", zIndex: "0", }}>*/}
        <MapContainer center={[dronePosition ? dronePosition.lat : 51.505, dronePosition ? dronePosition.lng : 0]} zoom={13} scrollWheelZoom={false} style={{height: "100%", width: "100%"}}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <RotatedMarker
                    position={[dronePosition ? dronePosition.lat : 51.505, dronePosition ? dronePosition.lng : 0]}
                    icon={GPSMapIcon}
                    rotationAngle={dronePosition ? dronePosition.yaw : 0}
                    style={{backgroundColor: "rgba(0,55,255,0.68)", transform: [{rotate: '50deg'}]}}
                >
                    <Popup>
                    A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                    
                </RotatedMarker>
            </MapContainer>
        </div>
        {/*</div>*/}
        
            
        </>
    )
}

export default Airmap;
