import React, { useState } from 'react'

import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";

function QuickStart(){
    const { t } = useTranslation(['quick_start', 'base']);

    return(
        <div>
            <h1>{t('header')}</h1>
            <p>{t('list.header')}</p>
            <h2>{t('list.register.title')}</h2>
            <p>{t('list.register.string_1')} <a href='https://air-link.space/registration'>https://air-link.space/registration. </a>
                {t('list.register.string_2')}</p>
            {/*<><h2>Подключение</h2>
            <img src="/image/QGC.png" />
            <p>PWR – индикатор питания.</p>
            <p>HOST – индикатор подключения к сети AIR-LINK. Мигание – попытка подключения; постоянно горящий светодиод – подключение установлено.</p>
            <p>RX/TX – индикаторы приема/передачи данных.</p>
            <p>NET – индикатор подключения к сети. Мигание с частотой два раза в секунду – модуль зарегистрирован в сети 4G/LTE.</p></>*/}
            <h2>{t('list.connection.title')}</h2>
            <p>{t('list.connection.string_1')}</p>
            <p>
                {t('list.connection.string_2')} <a href='https://air-link.space/drones'>https://air-link.space/drones </a><br/>
                {t('list.connection.string_3')}<br/>
                {t('list.connection.string_4')}
            </p>
            <p>{t('list.connection.string_5')}</p>

            <h2>{t('list.mission_planner.title')}</h2>
            <p>
                {t('list.mission_planner.string_1')} <a href='https://air-link.space/mp'>https://air-link.space/mp. </a><br/>
                {t('list.mission_planner.string_2')}</p>

            <h2>{t('list.qground_control.title')}</h2>
            <p>
                {t('list.qground_control.string_1')} <a href='https://air-link.space/qgc'>https://air-link.space/qgc. </a><br/>
                {t('list.qground_control.string_2')}
            </p>
            <p></p>
            <h2>{t('list.multiple_devices.title')}</h2>
            <p>
                {t('list.multiple_devices.string_1')}<br/>
                {t('list.multiple_devices.string_2')}<br/>
                {t('list.multiple_devices.string_3')}<br/>
            </p>
            <div className='wiki_img'>
                <img src="/image/wiki/wiki_modem_card.jpg" />
            </div>
            <p></p>
            <div className='wiki-video desktop'>
                <iframe width="760" height="415" src="https://www.youtube.com/embed/XszxSj__-Xg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className='wiki-video mobile'>
                <div class="thumb-wrap">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/XszxSj__-Xg" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default QuickStart;