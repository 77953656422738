import React, { useState, useEffect } from 'react'
import {Form, Button, DropdownButton, Dropdown, ProgressBar} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import { Container } from 'reactstrap';

function Users(){
    const [users, setUsers] = useState([]);

    useEffect(() => {
        console.log("users mount");
        getAllUsers();
    }, []);

    useEffect(() => {
        return () => {
            console.log("users unmount");
        };
    }, []);

    function getAllUsers(){
        axios.get('api/admin/getAllUsers')
        .then(response => {
            console.log(response)
            setUsers(response.data.users);
        });
    }

    function switchToUser(user){
        axios.post('api/admin/switchToUser', {user: user})
        .then(response => {
            console.log(response);
            window.location = "/drones";
        });
    }

    return(
        <div className='blocks'>
            {users.map(user => 
            <div>
                <Button onClick={() => switchToUser(user)}>{user}</Button>
            </div>
            )}
        </div>
    )
}
    

export default Users;
