import QrScanner from "qr-scanner";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import './qrScanner.css';
import {FlashlightOn} from "../../assets/icons/FlashlightOn";
import {FlashlightOff} from "../../assets/icons/FlashlightOff";

// https://github.com/nimiq/qr-scanner
const QrCodeScanner = (props) => {
    const [qrScannerHasFlash, setQrScannerHasFlash] = useState(false);
    const [isFlashOn, setFlashOn] = useState(false);

    const [cameras, setCameras] = useState([]);

    const qrScannerVideoId = 'qrScannerVideoId';
    const qrScannerToggleFlashId = 'qrScannerToggleFlashId';
    const qrScannerCameraSelectId = 'qrScannerCameraSelectId'

    useEffect(() => {
        const videoElement = document.getElementById(qrScannerVideoId);
        const toggleFlash = document.getElementById(qrScannerToggleFlashId);
        const cameraSelect = document.getElementById(qrScannerCameraSelectId);

        const qrScanner = new QrScanner(
            videoElement,
            (result) => {
                props.onDecode(result.data);
            },
            {
                highlightScanRegion: true,
            }
        );

        qrScanner.start().then(() => console.log('scan started'));

        qrScanner.hasFlash().then((result) => {
            setQrScannerHasFlash(result);
        })

        toggleFlash.addEventListener('click', () => {
            qrScanner.toggleFlash().then(() => {
                setFlashOn(qrScanner.isFlashOn());
            });
        })

        QrScanner.listCameras(true).then((cameras) => {
            setCameras(cameras);
        })


        cameraSelect.addEventListener('change', (e) => {
            qrScanner.setCamera(e.target.value).then(() => {
                qrScanner.hasFlash().then((result) => {
                    setQrScannerHasFlash(result);
                })
            });
        })

        return () => {
            qrScanner.stop();
            qrScanner.destroy()
        }
    }, [])

    const conditionalValues = {
        toggleFlashButtonText: () => {
            return isFlashOn ? <FlashlightOff /> : <FlashlightOn />;
        },
    }

    console.log(cameras);

    return (
        <>
            <video id={qrScannerVideoId}></video>
            <div className={'actionsContainer'}>
                <Button variant={'light'} id={qrScannerToggleFlashId} hidden={!qrScannerHasFlash}>
                    {conditionalValues.toggleFlashButtonText()}
                </Button>

                <select className={'form-select'} id={qrScannerCameraSelectId}>
                    {cameras.map((item) => (
                        <option value={item.id}>{item.label}</option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default QrCodeScanner;