import React, { useState, useEffect } from 'react'
import {Form, Button, Dropdown} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import QrCodeScannerModal from "../components/modal/qrCodeScannerModal/qrCodeScannerModal";

function Settings(){
    const [errorInfo, setErrorInfo] = useState(<div></div>);
    const [isQrCodeScannerModalOpen, setQrCodeScannerModalOpen] = useState(false);
    const [codeValue, setCodeValue] = useState('');
    const [version, setVersion] = useState('Version');

    const onChangeCodeInput = (event) => {
        event.preventDefault();
        setCodeValue(event.target.value);
    };

    const onScanQrCodeClick = () => {
        setQrCodeScannerModalOpen(true);
    }

    const onDecode = (decodedText) => {
        setCodeValue(decodedText.match("IMEI:([0-9]*)")[1]);
        setQrCodeScannerModalOpen(false);
    }

    useEffect(() => {
        console.log("settings mount");
      }, []);

    useEffect(() => {
        return () => {
            console.log("settings unmount");
        };
    }, []);

    function submitFormCreateImeiModem(event){
        event.preventDefault()
        console.log(codeValue)
    
        axios.post('api/settings/insertModem', {imei: codeValue, version: Number(version)})
            .then(response => {console.log(response); setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>imei: {codeValue} добавлен</div>)})
            .catch((error) => {
                console.log(error.response);
                setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>Ошибка добавления</div>);
            });
    }

    return(
        <div className='blocks'>
            <div className='body-box'>
                <h2>Create imei:</h2>
                <Form onSubmit={submitFormCreateImeiModem} style={{width: "300px"}}>
                    <Form.Control type="text" 
                        placeholder="IMEI" 
                        value={codeValue}
                        onChange={onChangeCodeInput}
                    />
                    <br />
                    <Button variant="secondary" onClick={onScanQrCodeClick} style={{width: "300px"}}>
                          Scan QR-Code
                      </Button>
                    <br />
                    <br />
                    
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width: "300px"}}>
                            {version}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setVersion('1002')}>1002</Dropdown.Item>
                            <Dropdown.Item onClick={() => setVersion('1003')}>1003</Dropdown.Item>
                            <Dropdown.Item onClick={() => setVersion('1004')}>1004</Dropdown.Item>
                            <Dropdown.Item onClick={() => setVersion('2001')}>2001</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <br />
                    <Button variant="primary" type="submit" style={{width: "300px"}}>
                        Insert
                    </Button>
                </Form>
                {errorInfo}
            </div>
            <QrCodeScannerModal
                show={isQrCodeScannerModalOpen}
                onDecode={onDecode}
                onClose={() => setQrCodeScannerModalOpen(false)}
            />
        </div>
    )
}

export default Settings;
