export const FlashlightOff = (props) => {
    return (
        <svg
            width={props.width ?? 24}
            height={props.height ?? 24}
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            fill={props.color}
        >
            <path
                d="M18 5V2H6v1.17L7.83 5zm-2 6 2-3V7H9.83L16 13.17zM2.81 2.81 1.39 4.22 8 10.83V22h8v-3.17l3.78 3.78 1.41-1.41L2.81 2.81z"
                fill={props.color}
            />
        </svg>
    )
}