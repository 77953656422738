import React, { useState, useEffect } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import "./Block.css"

function Block(props){
    const protocol = new signalR.JsonHubProtocol();
    const transport = signalR.HttpTransportType.WebSockets;

    useEffect(() => {
        //console.log("settings mount");
        
      }, []);

    useEffect(() => {
        return () => {
            //console.log("settings unmount");
        };
    }, []);

    function setClassName(name){

    }

    return(
        
    <>{/*<div className='blocks'>
            {props.children}
        </div>*/}
        {props.children}
    </>
    
    )
}

export default Block;