import React, { useState } from 'react'

import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";

function Ogn(){
    const { t } = useTranslation(['ogn', 'base']);

    return(
        <div>
            <h1>{t('header')}</h1>
            <p>
                {t('paragraph_1.string_1')}<br/>
                {t('paragraph_1.string_2')}<br/>
                {t('paragraph_1.string_3')}
            </p>
            <p>
                {t('paragraph_2.string_1')}<br/>
                {t('paragraph_2.string_2')} <a href='https://www.flightradar24.com'>FlightRadar24</a>,<br/>
                <a href='http://live.glidernet.org/'> OGN</a>, <a href='https://www.radarbox.com/'> RadarBox</a>.
            </p>
            <p>{t('paragraph_3')} <a href='https://ddb.glidernet.org/'>glidernet</a>.</p>
            <p>{t('paragraph_4')}</p>
            <p></p>
            
            <div className='wiki-video desktop'>
                <iframe width="760" height="415" src="https://www.youtube.com/embed/hgrt-Rcn-UQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className='wiki-video mobile'>
                <div class="thumb-wrap">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/hgrt-Rcn-UQ" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Ogn;