import React, { useState, useEffect } from 'react'
import {Form, Button, DropdownButton, Dropdown, ProgressBar} from "react-bootstrap";
import axios from 'axios';
import * as signalR from "@aspnet/signalr";
import { Container } from 'reactstrap';

function OnlineDrones(){
    const [onlineImei, setOnlineImei] = useState([]);

    useEffect(() => {
        console.log("OnlineDrones mount");
        getOnlineDroneInfo();
    }, []);

    useEffect(() => {
        return () => {
            console.log("OnlineDrones unmount");
        };
    }, []);

    function getOnlineDroneInfo(){
        axios.get('api/admin/getAllOnlineModem')
        .then(response => {
            console.log(response)
            setOnlineImei(response.data.imeis);
        });
    }

    return(
        <div className='blocks'>
            <div>Online: {onlineImei?.length}</div>
            {onlineImei.map(imei => <div>{imei.imei} {imei.email} {imei.ip}</div>)}
        </div>
    )
}
    

export default OnlineDrones;
