import React, { useState, useEffect } from 'react'
//import { useParams } from 'react-router-dom';
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";

function ForgotPasswordConfirmation(){
    const [errorInfo, setErrorInfo] = useState(<div></div>);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const { t } = useTranslation(['forgot_pass_confirm', 'base']);
    //const { param } = useParams();


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        setEmail(queryParams.get('email'));
        setToken(queryParams.get('token'));
      }, []);

    function submitForm(event){
        event.preventDefault()
        console.log(event.target[0].value);
        console.log(event.target[1].value);

        if(!(event.target[0].value === event.target[1].value)){
            setErrorInfo(<div style={{paddingTop: "10px"}}>{t('error_pass')}</div>);
            return;
        }

        axios.post('api/user/forgotPasswordConfirmation', { password1: event.target[0].value, password2: event.target[1].value,
        email: email, token: token})
            .then(response => {setErrorInfo(<div style={{paddingTop: "10px"}}>{t('success')}</div>)})
            .catch((error) => {
                console.log(error.response);
                setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>{t('error')}!</div>)
            });
    }

    return(
        <div className='blocks'>
            <div className='body-box'>
            <h1>{t('forgot_pass')}</h1>
            <Form onSubmit={submitForm}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>{t('password')}</Form.Label>
                    <Form.Control type="password" placeholder={t('password')} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>{t('password_confirm')}</Form.Label>
                    <Form.Control type="password" placeholder={t('password_placeholder')} />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {t('submit_button_text')}
                </Button>
            </Form>
            {errorInfo}
            </div>
        </div>
    )
}

export default ForgotPasswordConfirmation;
