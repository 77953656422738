import React, { useState, useEffect } from 'react'
import {useTranslation} from "react-i18next";

function ConfirmEmail(){
    const { t } = useTranslation(['confirm_email', 'base']);

    return(
        <div className='body-box blocks'>
            <h1>{t('string_1')}</h1>
            <p>{t('string_2')}</p>
        </div>
    )
}

export default ConfirmEmail;
