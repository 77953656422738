import React, { Component } from 'react';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';

export class Firmware extends Component {
  static displayName = Firmware.name;
   
  constructor (props) {
    super(props);

    this.state = {
      firmwareText: "",
      loading: true,
      firwareList: []
    };
  }

  componentDidMount() {
    this.updateFirwares();
  }

  toDownloadMode(){
      console.log("firmware");
      axios.get('serialTest/toDownloadMode')
      .then(response => console.log(response));
  }

  onSubmitFile = event =>{
    event.preventDefault();
    //console.log(event.target)
    console.log(event.target[0].files[0].name);
    console.log(event.target[1].value);
    let file = event.target[0].files[0];
    let version = event.target[1].value;
    let deviceVersion = event.target[2].value;
    let fileName = event.target[0].files[0].name;

    let data = new FormData();
    data.append('formFile', file);
    data.append('fileName', fileName);
    data.append('version', version);
    data.append('deviceVersion', deviceVersion);

    axios.post("/api/firmwares/firmwareLoad", data).then((resp) => {
      console.log("load file success");
      this.setState({
        firmwareText: "Firmware " + version + " loaded"
      });
    })
    .catch((error) => {
      this.setState({
        firmwareText: "Firmware " + version + " is not loaded!!!"
      });

      console.log(error.response);
    });
  }

    renderForecastsTable(forecasts) {
      return (
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Version</th>
              <th>Devise version</th>
              <th>Time create</th>
              <th>Is release</th>
              <th>Lenght</th>
              <th>Apply</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {forecasts.map(forecast =>
              <tr key={forecast.version}>
                <td>{forecast.version}</td>
                <td>{forecast.deviceVersion}</td>
                <td>{forecast.timeCreate}</td>
                <td>{forecast.isRelease ? "Release" : "Debug"}</td>
                <td>{forecast.lenght}</td>
                <td><button className="btn btn-primary" onClick={() => {this.setRelease(!forecast.isRelease, forecast.id);}}>{forecast.isRelease ? "To debug" : "To release"}</button></td>
                <td><button className="btn btn-danger" onClick={() => {this.delete(forecast.id);}}>X</button></td>
              </tr>
            )}
          </tbody>
        </table>
      );
    }
    

  render () {
    let firwares = this.state.loading
      ? <p><em>Loading...</em></p>
      : this.renderForecastsTable(this.state.firwareList);

    return (
      <div className='blocks'>
        <div className='body-box'>
            <h1>Firmware</h1>
            <Form onSubmit={this.onSubmitFile}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Firmware version</Form.Label>
              <Form.Control type="file"/>
            </Form.Group>
            <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="Version" />
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="Device version" />
            </Form.Group>
            
            <Button variant="primary" type="submit">
                    Load
              </Button>
            </Form>
            <h2>{this.state.firmwareText}</h2>

            {firwares}
            
        </div>
      </div>
    );
  }

  updateFirwares() {
    axios.get('/api/firmwares/getall')
        .then(response => {console.log(response.data); this.setState({loading: false, firwareList: response.data})});
  }

  setRelease(isRelease, id) {
    console.log(id);
    this.setState({loading: true})
    axios.post('/api/firmwares/setRelease', {isRelease: isRelease, id: id})
        .then(response => {this.updateFirwares()});
  }

  delete(id){
    console.log(id);
    this.setState({loading: true})
    axios.post('/api/firmwares/delete', {id: id})
        .then(response => {this.updateFirwares()});
  }
}
