import React, { useState } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import "./downloads.css"
import {useTranslation} from "react-i18next";

function Qgc(){
    const { t } = useTranslation(['qgc', 'base']);

    return(
        <div className='blocks'>
            <div className='body-box'>
                <div className='mission-download'>
                    <div className='mp-logo'>
                        <img src="/image/QGC.png" />
                        
                    </div>
                    <div>
                        <div className='heading'>{t('header')}</div>
                        <div className='text'>
                            <p>{t('string_1')}</p>
                            <p>{t('string_2')} <a href='http://qgroundcontrol.com/'>{t('href_text')}</a></p>
                        </div>
                    </div>
                    <div className='download-buttons'>
                        <p>{t('Download', { ns: "base"})}:</p>
                        <Button variant='success' href='https://disk.yandex.ru/d/JhFZsU2wXkdi1w' target='_blank'>Windows</Button>
                        <p></p>
                        <Button variant='success' href='https://disk.yandex.ru/d/luEjCNXiR2Zb6w' target='_blank'>Ubuntu</Button>
                        <p></p>
                        <Button variant='success' href='https://disk.yandex.ru/d/ZULkofNEfF9czg' target='_blank'>Android</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qgc;