import React, {Component, useEffect, useState} from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import {Form, NavDropdown} from "react-bootstrap";
import {Link, useNavigate} from 'react-router-dom';
import './NavMenu.css';
import axios from 'axios';
import Block from '../blocks/Block';
import {ReactComponent as Logo} from '../logo.svg';
import { Button } from 'react-bootstrap';
import i18n from "../i18n";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function NavMenu () {
  const { t } = useTranslation(['nav_menu']);

  const [collapsed, setCollapsed] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [login, setLogin] = useState('');

  useEffect(() => {
    axios.get('api/user/start')
      .then(response => {
        setIsLogin(true);
        setLogin(response.data.login);
        setIsAdmin(response.data.isAdmin);
        console.log(response);
      })
      .catch((error) => {console.log(error.response)});
    }, [window.location.href]);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const setLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  const setLanguageNav = (event) => {
    console.log(event);
    i18n.changeLanguage(event);
  };

    return (
      <header>
        <Block>
        <div className='blocks'>
        <Navbar  className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" dark>
        {/*<Navbar  className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" light>*/}
          
          <NavbarBrand tag={Link} style={{color: "white"}} to="/"><Logo/></NavbarBrand>
          {/*<select name="language" onChange={setLanguage}>
              <option value="ru">ru</option>
              <option value="en">en</option>
              <option value="en-US">en2</option>
    </select>*/}
          <NavbarToggler onClick={toggleNavbar} className="mr-2" color='white' />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
            <ul className="navbar-nav flex-grow">
            
              <NavItem>
                <NavLink tag={Link} className="text-white" to="/">{t('home_page')}</NavLink>
              </NavItem>
              <NavDropdown
                  id="nav-dropdown-white-example"
                  title="Language"
                  menuVariant="light"
                  onSelect={setLanguageNav}
                >
                  {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                  <NavDropdown.Item eventKey="en">en</NavDropdown.Item>
                  <NavDropdown.Item eventKey="ru">ru</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                  id="nav-dropdown-white-example"
                  title={t('downloads')}
                  menuVariant="light"
                >
                  <NavDropdown.Item href="/mp">Mission Planner</NavDropdown.Item>
                  <NavDropdown.Item href="/qgc">QGroundControl</NavDropdown.Item>
                  <NavDropdown.Item href="/alp">Air-Link Proxy</NavDropdown.Item>
              </NavDropdown>
              <NavItem>
                <NavLink tag={Link} className="text-white" to="/wiki/QuickStart">{t('documentation')}</NavLink>
              </NavItem>
              {
              isLogin ?
              <>
                {
                  isAdmin ?
                  <>
                    <NavItem>
                      <NavLink tag={Link} className="text-white" to="/firmware">Firmware</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-white" to="/settings">Settings</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-white" to="/statistics">Statistics</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-white" to="/onlineDrones">Online</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-white" to="/users">Users</NavLink>
                    </NavItem>
                  </>
                  :
                  <></>
                }
                {/*<NavItem>
                  <NavLink tag={Link} className="text-white" to="/map">Map</NavLink>
                </NavItem>*/}
                <NavItem>
                  <NavLink tag={Link} className="text-white" to="/drones">{t('uav')}</NavLink>
                </NavItem>
                <NavDropdown
                  id="nav-dropdown-white-example"
                  title={login}
                  menuVariant="light"
                >
                  {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="api/user/logout">{t('logout')}</NavDropdown.Item>
                </NavDropdown>
              </>

              :
              <>
                <NavItem>
                  <NavLink tag={Link} className="text-white" to="/registration">{t('registration')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-white" to="/login">{t('sign_in')}</NavLink>
                </NavItem>
              </>
            }
            </ul>
          </Collapse>
          
        </Navbar>
        </div>
        </Block>
      </header>
    );
}
