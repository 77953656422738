import React, { useState } from 'react'
import {Form, Button} from "react-bootstrap";
import axios from 'axios';
import {useTranslation} from "react-i18next";

function ForgotPassword(){
    const [errorInfo, setErrorInfo] = useState(<div></div>);
    const { t } = useTranslation(['forgot_pass', 'base']);

    function submitForm(event){
        event.preventDefault()
        console.log(event.target[0].value)

        axios.post('api/user/forgotPassword', { email: event.target[0].value})
            .then(response => {setErrorInfo(<div style={{paddingTop: "10px"}}>{t('success')}</div>)})
            .catch((error) => {
                console.log(error.response);
                setErrorInfo(<div style={{color: "red", paddingTop: "10px"}}>{t('error')}</div>);
            });
    }

    return(
        <div className='blocks'>
            <div className='body-box'>
            <h1>{t('forgot_pass')}</h1>
            <Form onSubmit={submitForm}>
            <Form.Group className="mb-3" controlId={t('address')}>
                <Form.Label>Адрес</Form.Label>
                <Form.Control type="email" placeholder={t('address_placeholder')} />
                <Form.Text className="text-muted">
                
                </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
                {t('submit_button_text')}
            </Button>
            </Form>
            {errorInfo}
            </div>
        </div>
    )
}

export default ForgotPassword;
